import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../flash/flash-service";
import {WorkflowService} from "../workflow/workflow-service";

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-contingent-view')
export class ShipVacancy {

    @bindable object;
    @bindable contextObjectRef;

    client;
    dialogService;
    flash;
    form;

    netContingent = false;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    bind()
    {
        console.log('TEST', this.contextObjectRef);
    }

    getBackground(cell) {
        let used = cell.predictedUsage;

        if (used > cell.contingent) {
            return {backgroundColor: '#bababa', color: 'black'};
        } else if (used > cell.contingent * 0.7) {
            return {backgroundColor: 'orange', color: 'white'};
        } else {
            return {backgroundColor: 'green', color: 'white'};
        }
    }

    getPopoverContent(cell) {

        if (cell.refs == null || cell.refs.length === 0) {
            return '';
        }

        let content = '<div>';

        for (let ref of cell.refs) {
            content += '<dl class="row" style="margin-bottom: 0;">';

            content += '<dt class="col-xs-3">'+ ref[1] +'</dt>';
            content += '<dt class="col-xs-3">'+ ref[3] +'</dt>';

            content += '</dl>';
        }

        content += '</div>';

        console.log('TEST' + content);

        return content;
    }

    submit() {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;

        return this.client.post('price/contingent/aggregation', data).then(data => {

            this.dates = data.data.columns;
            this.rows = data.data.rows;

            this._isSubmitting = false;

            return data;
        }).catch(error => {
            this._isSubmitting = false;

            throw error;
        });
    }
}
