import {inject} from 'aurelia-framework';
import moment from "moment-timezone";
import {MilitaryTimeValueConverter} from "../value-converter/military-time-value-converter";
import {currencies} from "../currency/model/currencies.model";

@inject(MilitaryTimeValueConverter)
export class DimensionConfig {

    militaryTime;

    weekDayMap = {
        1: 'Mo',
        2: 'Di',
        3: 'Mi',
        4: 'Do',
        5: 'Fr',
        6: 'Sa',
        7: 'So'
    };

    dimensionConfigs = {
        busRoute: {
            label: 'Bus Route',
            column: 'col-xs-4',
            hasDimension: ({busRoute}) => {
                return busRoute != null;
            },
            getDimension: ({busRoute}) => {
                return [busRoute.id, {busRoute}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-bus-routing/route-itinerary';
            },
            pick: ['busRoute'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.busRoute"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.busRoute?.id == line?.busRoute?.id;
            }
        },
        roomCategories: {
            label: 'Zimmer- / Kabinenkategorien',
            column: 'col-xs-4',
            hasDimension: ({roomCategories}) => {
                console.log("roomCategories", roomCategories);
                if(Array.isArray(roomCategories) && roomCategories.length == 0) {
                    return false;
                }
                return roomCategories != null;
            },
            getDimension: ({roomCategories}) => {
                return [roomCategories?.map((roomCategory) => roomCategory.id).join('-'), {roomCategories}];
            },
            show: (context) => {
                return context?.modelId === 'price/price-group'
                    || context?.modelId === 'tourism-accommodation/accommodation'
                    || context?.modelId === 'tourism-ship/ship'
                    || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['roomCategories'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.roomCategories"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.roomCategories?.map((roomCategory) => roomCategory.id)?.join('-') === line?.roomCategories?.map((roomCategory) => roomCategory.id)?.join('-');
            }
        },
        fare: {
            label: 'Verpflegung',
            column: 'col-xs-4',
            hasDimension: ({fare}) => {
                return fare != null;
            },
            getDimension: ({fare}) => {
                return [fare.id, {fare}];
            },
            show: (context) => {
                return context?.modelId === 'price/price-group' || context?.modelId === 'tourism-accommodation/accommodation' || context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['fare'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.fare"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.fare?.id == line?.fare?.id;
            }
        },
        tariff: {
            label: 'Tarif',
            column: 'col-xs-4',
            help: 'Wenn Sie einen Tarif eingeben, können Sie auch eine Zeile ohne Tarif anlegen (bei Nebenleistungen), dann ist die Leistung weiterhin ohne ausgewählten Tarif buchbar. Bei Fähren ist die Angabe von einem Tarif Pflicht',
            hasDimension: ({tariff}, lines) => {
                if (lines == null) {
                    return true;
                }

                //If any line has tariff, we set it as dimension, because tariff is optional in some cases
                return lines.some(line => line.tariff != null);
            },
            getDimension: ({tariff}) => {
                return [tariff?.id, {tariff}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-service/service'
                    || context?.modelId === 'tourism-ship/ship'
                    || context?.modelId === 'tourism-event-booking/event'
                    || context?.modelId === 'tourism-ferry/ferry'
                    || context?.modelId === 'tourism-transfer/transfer'
                    || context?.modelId === 'tourism-travel-package/travel-package'
                    || context?.modelId === 'tourism-rental-car/rental-car-vehicle'
                    || context?.modelId === 'tourism-new-taxi-transfer/taxi-transfer';
            },
            pick: ['tariff'],
            valueLabel: (dimensionValue) => {

                if (!dimensionValue.tariff) {
                    return '<i>Ohne Tariff</i>';
                }

                return '<reference-display reference.one-time="line.tariff"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.tariff?.id == line?.tariff?.id;
            }
        },
        destination: {
            label: 'Zieladresse',
            column: 'col-xs-4',
            hasDimension: ({destination}) => {
                return destination != null;
            },
            getDimension: ({destination}) => {
                return [destination.id, {destination}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-new-taxi-transfer/taxi-transfer';
            },
            pick: ['destination'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.destination"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.destination?.id === line?.destination?.id;
            }
        },
        eventTicket: {
            label: 'Ticket',
            column: 'col-xs-4',
            hasDimension: ({eventTicket}) => {
                return eventTicket != null;
            },
            getDimension: ({eventTicket}) => {
                return [eventTicket.id, {eventTicket}];
            },
            show: (context) => {
                return context?.modelId === 'tourism-event/event';
            },
            pick: ['eventTicket'],
            valueLabel: (dimensionValue) => {
                return '<reference-display reference.one-time="line.eventTicket"></reference-display>';
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.eventTicket?.id == line?.eventTicket?.id;
            }
        },
        date: {
            label: 'Datum',
            column: 'col-xs-4',
            clearfix: true,
            help: 'Geben Sie mehrere Zeilen an, um Preise für einen gewissen Zeitraum festzulegen. Saison ist dabei optional. Geben Sie die gleiche Saison an, um den Preis für mehrere Zeiträume auf einmal einzugeben.',
            hasDimension: ({from, to}) => {
                return from != null && to != null;
            },
            getDimension: ({from, to, seasonText}) => {
                return [from + '-' + to, {from, to, seasonText}];
            },
            show: (context) => {
                return context?.modelId !== 'tourism-event/event';
            },
            postProcess: (seasons) => {
                let groupedSeasons = {};
                let indexPerSeason = {};

                for (let [i, season] of seasons.entries()) {

                    if (season.seasonText) {
                        let index = indexPerSeason[season.seasonText] ?? i;

                        //important for order
                        if (!groupedSeasons['key' + index + season.seasonText]) {
                            groupedSeasons['key' + index + season.seasonText] = {seasonText: season.seasonText, dates: []};
                            indexPerSeason[season.seasonText] = i;
                        }

                        groupedSeasons['key' + index + season.seasonText].dates.push({from: season.from, to: season.to});
                    } else {
                        groupedSeasons['key' + i] = {dates: [{from: season.from, to: season.to}]};
                    }
                }

                console.log('GROUPED', groupedSeasons, Object.values(groupedSeasons));

                return Object.values(groupedSeasons);
            },
            pick: ['dates', 'seasonText', 'from', 'to'],
            valueLabel: (dimensionValue) => {
                let label = [];

                for (let date of dimensionValue.dates) {
                    label.push(moment(date.from).format('L') + ' - ' + moment(date.to).format('L'));
                }

                if (dimensionValue.seasonText != null) {
                    label.push('Saison: ' + dimensionValue.seasonText);
                }

                return label.join('<br>');
            },
            compare: (dimensionValue, line) => {

                let compareKeyFrom = line?.dates?.[0].from ?? line.from;
                let compareKeyTo = line?.dates?.[0].to ?? line.to;

                return dimensionValue.dates[0].from == compareKeyFrom
                    && dimensionValue.dates[0].to === compareKeyTo
                    && dimensionValue.seasonText == line.seasonText;
            }
        },
        count: {
            label: 'PAX',
            column: 'col-xs-4',
            hasDimension: ({countFrom, countTo}) => {
                return countFrom != null && countTo != null;
            },
            getDimension: ({countFrom, countTo}) => {
                return [countFrom + '-' + countTo, {countFrom, countTo}];
            },
            pick: ['countFrom', 'countTo'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.countFrom + ' - ' + dimensionValue.countTo;
            },
            compare: (dimensionValue, line) => {
                return dimensionValue.countFrom == line.countFrom && dimensionValue.countTo == line.countTo;
            }
        },
        duration: {
            label: 'Dauer',
            detailLabel: (context) => {
                return 'Dauer in Tagen / Nächten je nach Preis-Modus';
            },
            help: 'Je nach Preis-Modus handelt es sich hier um Nächte oder Tage. Bei Hotels ist der Standard auf Nächte eingestellt.',
            column: 'col-xs-4',
            hasDimension: ({durationFrom, durationTo}) => {
                return durationFrom != null && durationTo != null;
            },
            getDimension: ({durationFrom, durationTo}) => {
                return [durationFrom + '-' + durationTo, {durationFrom, durationTo}];
            },
            show: (context) => {

                if (context?.durationAsDays) {
                    return true;
                }

                return context?.modelId === 'price/price-group' || context?.modelId === 'tourism-accommodation/accommodation' || context?.modelId === 'tourism-ship/ship' || context?.modelId === 'tourism-ferry/ferry' || context?.modelId === 'tourism-rental-car/rental-car-vehicle';
            },
            pick: ['durationFrom', 'durationTo'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.durationFrom + ' - ' + dimensionValue.durationTo;
            },
            compare: (dimensionValue, line) => {
                return dimensionValue.durationFrom == line.durationFrom && dimensionValue.durationTo == line.durationTo;
            }
        },
        weekDays: {
            label: 'Wochentage',
            column: 'col-xs-4',
            clearfix: true,
            hasDimension: ({weekDays}) => {
                return weekDays && weekDays.length > 0;
            },
            getDimension: ({weekDays}) => {
                return [weekDays.join('-'), {weekDays}];
            },
            show: (context) => {
                return context?.modelId !== 'tourism-event/event';
            },
            pick: ['weekDays'],
            valueLabel: (dimensionValue) => {
                return dimensionValue.weekDays.map(weekDay => this.weekDayMap[weekDay]).join(', ');
            },
            compare: (dimensionValue, line) => {
                return dimensionValue?.weekDays?.join('-') == line?.weekDays?.join('-');
            }
        },
        time: {
            label: 'Zeit',
            detailLabel: (context) => {
                return 'Leistungsbeginn (bis optional)';
            },
            help: 'Geben Sie mehrere Zeilen an, um Preise für einen gewisse Uhrzeit festzulegen. Sie können das Bis freilassen, dann zählt der Preis nur für die spezifische Uhrzeit. Preise werden anhand des Beginns der Leistung ermittelt. Saison ist dabei optional. Geben Sie die gleiche Saison an, um den Preis für mehrere Zeiträume auf einmal einzugeben.',
            column: 'col-xs-4',
            hasDimension: ({timeFrom, timeTo}) => {
                return timeFrom != null;
            },
            getDimension: ({timeFrom, timeTo, timeText}) => {
                return [timeFrom + '-' + timeTo, {timeFrom, timeTo, timeText}];
            },
            postProcess: (times) => {
                let groupedTimes = {};
                let indexPerSeason = {};

                for (let [i, time] of times.entries()) {

                    if (time.timeText) {
                        let index = indexPerSeason[time.timeText] ?? i;

                        //important for order
                        if (!groupedTimes['key' + index + time.timeText]) {
                            groupedTimes['key' + index + time.timeText] = {timeText: time.timeText, times: []};
                            indexPerSeason[time.timeText] = i;
                        }

                        groupedTimes['key' + index + time.timeText].times.push({timeFrom: time.timeFrom, timeTo: time.timeTo});
                    } else {
                        groupedTimes['key' + i] = ({times: [{timeFrom: time.timeFrom, timeTo: time.timeTo}]});
                    }
                }

                return Object.values(groupedTimes);
            },
            show: (context) => {
                if (context?.modelId === 'tourism-service/service' && context?.durationAsDays) {
                    return false;
                }

                return context?.modelId === 'tourism-service/service' ||
                    context?.modelId === 'tourism-transfer/transfer' ||
                    context?.modelId === 'tourism-event-booking/event' ||
                    context?.modelId === 'tourism-ferry/ferry';
            },
            pick: ['times', 'timeText'],
            valueLabel: (dimensionValue) => {
                let label = [];

                for (let time of dimensionValue.times) {
                    label.push(this.militaryTime.toView(time.timeFrom) + (time.timeTo ? (' - ' + this.militaryTime.toView(time.timeTo)) : ''));
                }

                if (dimensionValue.timeText != null) {
                    label.push('Beschreibung: ' + dimensionValue.timeText);
                }

                return label.join('<br>');
            },
            compare: (dimensionValue, line) => {

                let compareKeyFrom = line?.times?.[0].timeFrom ?? line.timeFrom;
                let compareKeyTo = line?.times?.[0].timeTo ?? line.timeTo;

                return dimensionValue.times[0].timeFrom == compareKeyFrom
                    && dimensionValue.times[0].timeTo == compareKeyTo
                    && dimensionValue.timeText == line.timeText;
            }
        },
    }

    constructor(militaryTime) {
        this.militaryTime = militaryTime;
    }

    _convertToView(amount, currency = 'EUR') {
        return amount / Math.pow(10, currencies[currency].decimal_digits);
    }

    _convertToModel(amount, currency = 'EUR') {
        return Math.round(amount * Math.pow(10, currencies[currency].decimal_digits));
    }

    keys() {
        return Object.keys(this.dimensionConfigs);
    }

    get(dimensionKey) {
        return this.dimensionConfigs[dimensionKey];
    }

    isVisible(dimensionKey, context, contingent) {
        let config = this.dimensionConfigs[dimensionKey];

        if (
            contingent && (
                dimensionKey !== 'date' &&
                dimensionKey !== 'weekDays' &&
                dimensionKey !== 'roomCategories' &&
                dimensionKey !== 'tariff' &&
                dimensionKey !== 'busRoute'
            )
        ) {
            return false;
        }

        return !config.show || config.show(context);
    }

    getKey(dimensionTypes, dimensions, line) {
        let completeIndex = [];

        for (let i = 0; i < dimensions.length; i++) {

            let dimensionValues = dimensions[i];
            let dimensionTypeValue = dimensionTypes[i].type;

            for (let j = 0; j < dimensionValues.length; j++) {

                let dimensionValue = dimensionValues[j];

                if (this.dimensionConfigs[dimensionTypeValue].compare(dimensionValue, line)) {
                    completeIndex.push(j);
                    break;
                }
            }
        }

        return completeIndex.join('-');
    }
}
