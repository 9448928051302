import React, {useRef} from "react";
import {Icon, Popover, Whisper} from "rsuite";
import moment from 'moment-timezone';
import ContingentHover from "./contingentHover";

export const ContingentDisplay = ({dates, rows, netContingent}) => {

    const getBackground = (cell, releaseDateReached) => {
        let free = cell.predictedFree;

        if (cell.disableBooking) {
            return {backgroundColor: '#f3f3f3', color: '#999'};
        } else if (releaseDateReached) {
            return {backgroundColor: '#f3f3f3', color: '#999'};
        } else if (free < 0.0) {
            return {backgroundColor: 'red', color: 'white'};
        } else if (free == 0.0) {
            return {backgroundColor: '#f3f3f3', color: '#999'};
        } else if (free < cell.contingent * 0.2) {
            return {backgroundColor: 'orange', color: 'white'};
        } else {
            return {backgroundColor: 'green', color: 'white'};
        }
    }

    return (
        <table className="table table-sm contingent-table">

            <thead>
            <tr>
                <th>Datum</th>
                { dates.map((date, index) => {
                    let day = moment(date).format("DD");
                    let month = moment(date).format("MM");

                    return (day == 1 && month == 1) || index == 0 ? <th className={"first-cell"}>
                        {moment(date).format("YY")}
                    </th> : <th></th>
                }) }
            </tr>

            <tr>
                <th></th>
                { dates.map((date, index) => {
                    let day = moment(date).format("DD");

                    return day == 1 || index == 0 ? <th className={"first-cell"}>
                        {moment(date).format("MM")}
                    </th> : <th></th>
                }) }
            </tr>
            <tr>
                <th></th>
                { dates.map((date, index) => {
                    let day = moment(date).format("DD");

                    return <th className={day == 1 || index == 0 ? "first-cell" : ""}>
                        {moment(date).format("DD")}
                    </th>
                }) }
            </tr>
            <tr>
                <th></th>
                { dates.map((date, index) => {
                    let day = moment(date).format("DD");

                    return <th className={day == 1 || index == 0 ? "first-cell" : ""}>
                        {moment(date).format("dd")}
                    </th>
                }) }
            </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => {

                    if (row.type === 'group') {
                        return <tr>
                            <th>{row?.title?.objectLabel}</th>
                            <th colSpan={dates.length}></th>
                        </tr>;
                    }

                    return <tr>
                            { row.level === 0 ? <th>{row?.title?.objectLabel}</th> : <td>{row?.title?.objectLabel}</td> }
                            { row.columns?.map((cell, index) => {
                                if (cell == null) {
                                    return <td className="empty-cell"></td>;
                                }

                                const releaseDateReached = moment(cell.releaseDate).isBefore(moment());

                                return <td className="contingent-cell" style={getBackground(cell, releaseDateReached)}>

                                    <Whisper trigger="hover"
                                             enterable={true}
                                             speaker={
                                                 <Popover>
                                                     <ContingentHover item={cell}></ContingentHover>
                                                 </Popover>
                                             }>
                                        <span>{ cell.disableBooking ? 'S' : (releaseDateReached ? 'X' : cell.predictedFree) }</span>
                                    </Whisper>
                                </td>;
                            }) }
                    </tr>

                })}
            </tbody>
        </table>
    );

};

export default ContingentDisplay;
