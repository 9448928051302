import React, {useEffect, useState} from "react";
import {Icon, Popover, Whisper} from "rsuite";
import moment from 'moment-timezone';
import {Container} from "aurelia-framework";
import {ModelLabelService} from "../reference/model-label-service";
import Client from "../api/client";
import {useApiPost} from "../api/use-fetch";
import ReactReferenceDisplay from "../editor/blocks/react-reference-display";

export const ContingentHover = ({item}) => {

    let client = Container.instance.get(Client);

    const [result, setResult] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            const data = await  client.post('price/contingent/refs', item.conditions);

            setResult(data);
        };

        fetchData();

    }, [item]);

    const {data, loading, updating} = useApiPost('price/contingent/refs', item.conditions);

    return (
        <p>
            {item.disableBooking ? <b>Stopsale<br /></b> : null}
            <span>Kontingent: {item.contingent}</span><br/>
            <span>In Benutzung: {item.predictedUsage}</span><br />
            <span>Frei: {item.predictedFree}</span><br/>
            <span>Release-Datum: {moment(item.releaseDate).format('L')}</span><br/><br/>

            <span>
                { data && data.map((element) => {

                    return <span>
                        <p><b>{element.title}</b></p>
                        { element.refs.map((order) => {

                            return <>
                                <b><ReactReferenceDisplay reference={order.order}></ReactReferenceDisplay></b><br/>
                                <span>Benutzt: {order.predictedUsage}</span><br />
                            </>;

                        }) }
                    </span>;

                }) }
            </span>
        </p>
    );

};

export default ContingentHover;
