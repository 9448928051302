import {RouterHistory} from "../router/history";

export class PinService {

    static get pinned() {
        return JSON.parse(localStorage.getItem('sio-pinned') || '[]');
    }

    static set pinned(pinned) {
        return localStorage.setItem('sio-pinned', JSON.stringify(pinned));
    }

    static isFeatureEnabled(user) {
        return user?.instance?.settings?.instanceGeneral?.enablePinFeature || false;
    }

    static isPinned() {
        const history = RouterHistory.history;
        const pinned = PinService.pinned;

        const pinnedItem = history[0];

        let i = 0;

        while (i < pinned.length) {
            if (pinned[i].href === pinnedItem.href) {
                return true;
            }
            i++;
        }

        return false;
    }

    static addPinned() {
        const history = RouterHistory.history;
        const pinned = PinService.pinned;

        const pinnedItem = history[0];

        let i = 0;

        while (i < pinned.length) {
            if (pinned[i].href === pinnedItem.href) {
                pinned.splice(i, 1);
                PinService.pinned = pinned;
                return false;
            }
            i++;
        }

        pinned.unshift(pinnedItem);

        PinService.pinned = pinned;

        return true;
    }
}
